import { useEffect, useState } from "react";
import { useCachedObjectMemo } from "@mssi/pssp-shared/web";

const useStorage = (store: Storage, key: string, initialValue?: string): any => {
  const [value, setValue] = useState<any>(store.getItem(key) ?? initialValue);

  useEffect(
    () => {
      const onStorageEvent = (e: StorageEvent) => {
        if (e.storageArea !== store || e.key !== key) {
          return;
        }

        setValue(e.newValue ?? initialValue);
      };

      window.addEventListener("storage", onStorageEvent);

      return () => {
        window.removeEventListener("storage", onStorageEvent);
      };
    },
    [store, initialValue, key],
  );

  return useCachedObjectMemo(value);
};

export default useStorage;
