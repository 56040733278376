import { Dispatch, Middleware } from "redux";
import { RootActionTypes } from "./root-action";
import { RootState } from "./redux-state";
import {
  downloadReportSetDone,
  downloadReportSetDownloading,
  downloadReportSetError,
  REPORT_DOWNLOAD_CLICK,
  REPORT_DOWNLOAD_REGEX,
  REPORT_DOWNLOAD_START,
} from "./report-download-actions";
import { downloadDocument } from "../api/documents";
import { ReportParams } from "../types/document";
import { getReportDownloadPermissions } from "./report-download-shared";

type DispatchMiddleware = Middleware<Dispatch<RootActionTypes>, RootState, Dispatch<RootActionTypes>>;

const reportDownloadMiddleware: DispatchMiddleware = (storeAPI) => (next: Dispatch<RootActionTypes>) => (action: RootActionTypes) => {
  if (!REPORT_DOWNLOAD_REGEX.test(action.type)) {
    next(action);
    return;
  }

  let doDownload = false;
  const state = storeAPI.getState();
  const { reportDownload, currentCustomer } = state;

  const currentCustomerId = currentCustomer?.customer?.id;

  let {
    customerIds,
    internal,
    reportFileName,
    reportUrl,
    reportHasCustomerChoice,
    sendThisCustomerId,
    reportHasInternalExternalChoice,
    reportTitle,
    passwordChoice,
    password,
  } = reportDownload;

  if (action.type === REPORT_DOWNLOAD_CLICK) {
    const {
      userHasCustomerChoice,
      userHasInternalExternalChoice,
      internal: overrideInternal,
      customerIds: overrideCustomerIds,
    } = getReportDownloadPermissions(state, action.reportHasCustomerChoice, action.reportHasInternalExternalChoice);

    internal = overrideInternal;
    customerIds = overrideCustomerIds;
    reportFileName = action.fileName;
    reportUrl = action.url;
    reportHasCustomerChoice = action.reportHasCustomerChoice;
    reportHasInternalExternalChoice = action.reportHasInternalExternalChoice;
    reportTitle = action.reportTitle;
    passwordChoice = action.passwordChoice;
    sendThisCustomerId = action.sendThisCustomerId;

    if (!userHasCustomerChoice && !userHasInternalExternalChoice && !passwordChoice) {
      doDownload = true;
    }
  }

  if (doDownload || action.type === REPORT_DOWNLOAD_START) {
    if (!reportUrl) {
      next(downloadReportSetError("Unsupported download type"));
      return;
    }

    const params: ReportParams = {};

    if (reportHasInternalExternalChoice) {
      params.internal = internal;
    }

    if (reportHasCustomerChoice) {
      params.customerIds = customerIds;
    }

    if (sendThisCustomerId) {
      params.customerId = currentCustomerId;
    }

    if (passwordChoice) {
      params.password = password;
    }

    next(downloadReportSetDownloading(reportTitle));

    downloadDocument(reportUrl, params, reportFileName ?? "report")
      .then(() => {
        next(downloadReportSetDone());
      })
      .catch((error: Error) => {
        next(downloadReportSetError(error.message));
      });

    return;
  }

  next(action);
};

export default reportDownloadMiddleware;
