import { initialReportDownloadState, ReportDownloadState, RootState } from "./redux-state";
import { RootActionTypes } from "./root-action";
import {
  REPORT_DOWNLOAD_CLICK,
  REPORT_DOWNLOAD_EXIT,
  REPORT_DOWNLOAD_REGEX,
  REPORT_DOWNLOAD_SET_CUSTOMERS,
  REPORT_DOWNLOAD_SET_DONE,
  REPORT_DOWNLOAD_SET_DOWNLOADING,
  REPORT_DOWNLOAD_SET_ERROR,
  REPORT_DOWNLOAD_SET_INTERNAL,
  REPORT_DOWNLOAD_SET_PASSWORD,
  REPORT_DOWNLOAD_START,
} from "./report-download-actions";
import { getReportDownloadPermissions } from "./report-download-shared";
import ReportModalState from "../components/modals/reports/report-modal-state";

const reportDownloadReducer = (
  state: ReportDownloadState = initialReportDownloadState,
  action: RootActionTypes,
  rootState: RootState,
): ReportDownloadState => {
  if (!REPORT_DOWNLOAD_REGEX.test(action.type)) {
    return state;
  }

  switch (action.type) {
    case REPORT_DOWNLOAD_CLICK:
      return {
        ...state,
        ...getReportDownloadPermissions(
          rootState,
          action.reportHasCustomerChoice,
          action.reportHasInternalExternalChoice,
        ),
        reportFileName: action.fileName,
        reportTitle: action.reportTitle,
        reportUrl: action.url,
        reportHasCustomerChoice: action.reportHasCustomerChoice,
        sendThisCustomerId: action.sendThisCustomerId,
        reportHasInternalExternalChoice: action.reportHasInternalExternalChoice,
        modalState: ReportModalState.PICK_CUSTOMER_ANDOR_TYPE,
        allowMultipleCustomers: action.allowMultipleCustomers,
        passwordChoice: action.passwordChoice,
      };
    case REPORT_DOWNLOAD_SET_INTERNAL:
      return { ...state, internal: !!action.internal };
    case REPORT_DOWNLOAD_SET_CUSTOMERS:
      return { ...state, customerIds: action.customerIds || [] };
      case REPORT_DOWNLOAD_SET_PASSWORD:
        return { ...state, password: action.password };
    case REPORT_DOWNLOAD_START:
      // this should be captured by report download middleware
      return state;
    case REPORT_DOWNLOAD_SET_DOWNLOADING:
      return {
        ...state,
        modalState: ReportModalState.DOWNLOADING,
        reportTitle: action.reportTitle ?? state.reportTitle,
      };
    case REPORT_DOWNLOAD_SET_ERROR:
      return {
        ...state,
        error: action.error,
        modalState: ReportModalState.ERROR,
      };
    case REPORT_DOWNLOAD_SET_DONE:
      return {
        ...state,
        modalState: ReportModalState.DONE,
      };
    case REPORT_DOWNLOAD_EXIT:
      return { ...initialReportDownloadState };
    default:
      console.warn(`Unrecognized action ${action.type}`);
      return state;
  }
};

export default reportDownloadReducer;
