import React from "react";
import styled from "styled-components";
import { Field, FieldProps, Form, Formik } from "formik";
import { useCachedObjectMemo } from "@mssi/pssp-shared/web";
import AutoSubmit from "../../../components/forms/auto-submit";
import FilePickerSDM, { OnFileInfoProps } from "../../../components/forms/file-picker-sdm";
import { IconButton } from "@veneer/core";
import { supportUploadTypes } from "../../../types/accepted-file";

interface Props extends FieldProps {
  label?: string;
  subTitle?: string;
  required?: boolean;
  accept?: string;
  allowEdit?: boolean;
  allowRemove?: boolean;
  value?: Record<string, string>;
}

const AttachmentTitle = styled.p`
  display: flex;
  color: ${(props) => props.theme.colors.gray};
  font-size: 17px;
  font-weight: 600;
  margin-top: 22px;
`;

const AttachmentSubTitle = styled.p`
  display: flex;
  color: ${(props) => props.theme.colors.grey};
  font-size: 15px;
  margin: 2px 0 0 5px;
`;

const RedIconButton = styled(IconButton)`
  color: ${(props) => props.theme.colors.red};
  &:hover, &:active, &:focus {
    color: ${(props) => props.theme.colors.red};
  }
`;

const StyledList = styled.ul`
  padding-left: 0 !important;

  li {
    display: flex;
    flex-direction: row;
    background-color: ${(props) => props.theme.colors.lightBlue1};
    list-style: none;
    padding: 0;
    margin: 6px 0;
    vertical-align: middle;
    
    span {
      flex: 1 0;
      align-self: center;
      padding: 0 8px;
    }

    button {
      flex: 0 0;
    }
  }
`;

interface FileInfo {
  id: string;
  [key: string]: string;
}

interface FormValues {
  files: FileInfo[];
  newFile: null;
}

const AttachFiles: React.FC<Props> = ({ label, subTitle, field: { name, value }, form }) => {
  const files: FileInfo[] = useCachedObjectMemo(value || []);
  const initialValues: FormValues = useCachedObjectMemo({ files, newFile: null });

  return (
    <>
      {label && (
        <AttachmentTitle>
          {label}
          {subTitle && <AttachmentSubTitle>{subTitle}</AttachmentSubTitle>}
        </AttachmentTitle>
      )}
      <Formik initialValues={initialValues} enableReinitialize onSubmit={() => undefined}>
        {({ values, submitForm }) => (
          <Form>
            <AutoSubmit values={values} submitForm={submitForm} />
            <StyledList>
              {files?.map(({ id, fileName }, index) => (
                <li key={`${id}.${index}`}>
                  <span>{fileName}</span>
                  <RedIconButton
                    icon="delete"
                    title={("btn.delete")}
                    onClick={() => {
                      const index = files.findIndex((it) => it.id === id);
                      if (index >= 0) {
                        const newValue = [...files];
                        newValue.splice(index, 1);
                        form.setFieldValue(name, newValue);
                      }
                    }}
                    type="button"
                  />
                </li>
              ))}
            </StyledList>
            <Field
              name="newFile"
              component={FilePickerSDM}
              accept={supportUploadTypes}
              value={values.newFile}
              allowEdit
              allowRemove={false}
              onFileInfo={({ id, fileName }: OnFileInfoProps) => {
                if (!id || !fileName) {
                  return;
                }

                const index = files.findIndex((it) => it.id === id);
                const result = [...files];

                if (index >= 0) {
                  result[index] = { id, fileName };
                } else {
                  result.push({ id, fileName });
                }

                form.setFieldValue(name, result);
                form.setFieldValue("newFile", null);
              }}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AttachFiles;
