import { useMemo, useState, Dispatch, SetStateAction } from "react";
import copy from "clone-deep";
import { getTableProp } from "./get-table-prop";
import {
  Column,
  Property,
  SearchType,
  Filter,
  FilterInfo,
  FilterOption,
  SelectedFilter,
  UseFilterContext,
  BackendColumn,
  SelectedBackendFilter,
  SelectedFilterBase,
  UseBackendFilterContext,
} from "./types";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { BackendTableFilters, FilterItemCount } from "../../types/table-backend-types";
import { useCachedObjectMemo } from "@mssi/pssp-shared/web";

const normalize = (value: any): string => `${value || ""}`;

const toggleCallback = <T extends any>(
  choices: SelectedFilterBase<T>[],
  setChoices: Dispatch<SetStateAction<SelectedFilterBase<T>[]>>,
) =>
  (property: any, value: any, isSelected?: boolean) => {
    const normalized = normalize(value);
    let index = choices.findIndex((choice) => choice.property === property);

    if (index < 0) {
      choices.push({ property, selections: [] });
      index = choices.length - 1;
    }

    const { selections } = choices[index];
    const isCurrentlySelected = selections.includes(normalized);

    if (isSelected !== false && !isCurrentlySelected) {
      selections.push(normalized);
      setChoices([...choices]);
    } else if (isSelected !== true && isCurrentlySelected) {
      const i = selections.indexOf(normalized);

      if (i >= 0) {
        selections.splice(i, 1);
      }

      if (!selections.length) {
        choices.splice(index, 1);
      }

      setChoices([...choices]);
    }
  };

const selectCallback = <T extends any>(
  choices: SelectedFilterBase<T>[],
  setChoices: Dispatch<SetStateAction<SelectedFilterBase<T>[]>>,
) =>
  (property: any, values: any[]) => {
    const normalizedValues = values.map(normalize);
    let index = choices.findIndex((choice) => choice.property === property);

    if (index < 0) {
      choices.push({ property, selections: [] });
      index = choices.length - 1;
    }

    choices[index].selections = normalizedValues;

    if (!normalizedValues.length) {
      choices.splice(index, 1);
    }

    setChoices([...choices]);
  };

const clearCallback = <T extends any>(
  choices: SelectedFilterBase<T>[],
  setChoices: Dispatch<SetStateAction<SelectedFilterBase<T>[]>>,
) =>
  (property?: any) => {
    if (property === undefined) {
      setChoices([]);
    } else {
      const index = choices.findIndex((choice) => choice.property === property);

      if (index >= 0) {
        choices.splice(index, 1);
        setChoices([...choices]);
      }
    }
  };

const filterValueCallback = <T extends any>(filters: Filter<T>[]) => (property?: Property<T>): any[] => {
  const filter = filters.find((filter) => filter.property === property);

  if (!filter) {
    return [];
  }

  return filter.options
    .filter(({ selected }) => selected)
    .map(({ value }) => value);
};

const filterOptionsCallback = <T extends any>(filters: Filter<T>[]) => (property?: Property<T>): any[] => {
  const filter = filters.find((filter) => filter.property === property && filter.options.find((option) => option.value !== null));

  if (!filter) {
    return [];
  }

  return filter.options;
};

const buildOptions = <T extends any>(items: T[], property: Property<T>, t: TFunction) => {
  const set: { [key: string]: boolean } = {};
  const result: FilterOption[] = [];

  for (const item of items) {
    const value = getTableProp(item, property, t);
    const normalized = normalize(value);

    if (!Object.prototype.hasOwnProperty.call(set, normalized)) {
      set[normalized] = true;

      result.push({
        value,
        normalized,
        selected: false,
        count: 0,
      });
    }
  }

  result.sort((a, b) => a.normalized.localeCompare(b.normalized));

  return result;
};

const buildBackendOptions = (items: FilterItemCount[]): FilterOption[] => {
  const set: { [key: string]: boolean } = {};
  const result: FilterOption[] = [];

  for (const item of items || []) {
    const { property: value, label: text = value } = item;
    const normalized = normalize(text);

    if (!Object.prototype.hasOwnProperty.call(set, normalized)) {
      set[normalized] = true;

      result.push({
        value,
        normalized,
        selected: false,
        count: item.count,
      });
    }
  }

  result.sort((a, b) => a.normalized.localeCompare(b.normalized));

  return result;
};

const buildFilters = <T extends any>(items: T[], columns: Column<T>[], t: TFunction) => columns
  .filter(({ searchType, enabled }) => enabled !== false && searchType === SearchType.Filter)
  .map(({ property, label = property }) => ({
    property,
    label: `${label}`,
    options: buildOptions(items, property, t),
    hasSelectedItems: false,
  }));

const applyChoices = <T extends any>(
  choices: SelectedFilter<T>[],
  itemInfo: FilterInfo<T>[],
  t: TFunction,
) => {
  for (const { property, selections } of choices) {
    for (const info of itemInfo) {
      const value = normalize(getTableProp(info.item, property, t));

      if (selections.length && !selections.includes(value)) {
        info.included = false;

        if (!info.excludedBy.includes(property)) {
          info.excludedBy.push(property);
        }
      }
    }
  }
};

const applyCountsToFilters = <T extends any>(
  filters: Filter<T>[],
  itemInfo: FilterInfo<T>[],
  columns: Column<T>[],
  t: TFunction,
) => {
  for (const { property } of columns) {
    const filter = filters.find((it) => it.property === property);

    if (filter) {
      for (const info of itemInfo) {
        const value = normalize(getTableProp(info.item, property, t));
        const filterOption = filter.options.find((option) => option.value === value);

        if (filterOption && (info.included || (info.excludedBy.length === 1 && info.excludedBy[0] === property))) {
          filterOption.count += 1;
          filter.hasSelectedItems = true;
        }
      }
    }
  }
};

const applySelectionsToFilters = <T extends any>(
  filters: Filter<T>[],
  choices: SelectedFilter<T>[],
) => {
  for (const filter of filters) {
    const choice = choices.find((it) => it.property === filter.property);

    if (choice) {
      for (const option of filter.options) {
        if (choice.selections.includes(option.normalized)) {
          option.selected = true;
        }
      }
    }
  }
};

const filterItems = <T extends any>(itemInfo: FilterInfo<T>[], items: T[]): T[] => itemInfo
  .filter(({ included }) => included)
  .map(({ index }) => items[index]);

export const buildItemInfo = <T extends any>(items: T[]): FilterInfo<T>[] => items
  .map((item: T, index: number): FilterInfo<T> => ({ item, index, included: true, excludedBy: [] }));

export function useFilter<T>(items: T[], columns: Column<T>[]): UseFilterContext<T> {
  const { t } = useTranslation();

  const [choices, setChoices] = useState<SelectedFilter<T>[]>([]);

  const toggleFilter = useMemo(() => toggleCallback(choices, setChoices), [choices]);
  const selectFilter = useMemo(() => selectCallback(choices, setChoices), [choices]);
  const clearFilter = useMemo(() => clearCallback(choices, setChoices), [choices]);

  const baseInfo = useMemo(() => buildItemInfo(items), [items]);
  const baseFilters: Filter<T>[] = useMemo(() => buildFilters(items, columns, t), [items, columns, t]);

  const filters = copy(baseFilters);
  const info = copy(baseInfo);

  applyChoices(choices, info, t);
  applyCountsToFilters(filters, info, columns, t);
  applySelectionsToFilters(filters, choices);

  const result = filterItems(info, items);

  const getFilterValues = useMemo(() => filterValueCallback(filters), [filters]);
  const getFilterOptions = useMemo(() => filterOptionsCallback(filters), [filters]);

  return {
    result,
    filters,
    toggleFilter,
    selectFilter,
    clearFilter,
    getFilterValues,
    getFilterOptions,
    choices,
  };
}
export function useBackendFilter<T>(filterInfo: BackendTableFilters, columns: BackendColumn<T>[]): UseBackendFilterContext<T> {
  const [choices, setChoices] = useState<SelectedBackendFilter<T>[]>([]);

  const toggleFilter = useMemo(() => toggleCallback(choices, setChoices), [choices]);
  const selectFilter = useMemo(() => selectCallback(choices, setChoices), [choices]);
  const clearFilter = useMemo(() => clearCallback(choices, setChoices), [choices]);

  const filters: Filter<T>[] = useMemo(
    () => {
      const baseFilters = columns
        .filter(({ searchType, enabled }) => enabled !== false && searchType === SearchType.Filter)
        .map((column) => ({
          property: column.property,
          label: `${column.label || column.property}`,
          options: buildBackendOptions(filterInfo[column.property]),
          hasSelectedItems: false,
        }));

      const result = copy(baseFilters);

      for (const { property, selections } of choices) {
        for (const filter of result) {
          if (filter.property === property) {
            for (const option of filter.options) {
              option.selected = selections.includes(normalize(option.value));
              filter.hasSelectedItems = true;
            }
          }
        }
      }

      return result;
    },
    [filterInfo, columns, choices],
  );

  const getFilterValues = useMemo(() => filterValueCallback(filters), [filters]);
  const getFilterOptions = useMemo(() => filterOptionsCallback(filters), [filters]);

  const cachedFilters = useCachedObjectMemo(filters);

  return {
    filters: cachedFilters,
    choices,
    toggleFilter,
    selectFilter,
    clearFilter,
    getFilterValues,
    getFilterOptions,
  };
}
