import { useUserPreference } from "../../../redux/auth-hooks";
import { tryParseJson } from "@mssi/pssp-shared";
import { VeneerTablePreferences } from "./config-types";
import { BackendColumn, Key } from "../types";
import { useCallback } from "react";
import { asyncUpdatePreference } from "../../../redux/auth-thunks";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { RootActionTypes } from "../../../redux/root-action";
import { useCachedObjectMemo } from "@mssi/pssp-shared/web";

export type TablePreferencesResult = [
  VeneerTablePreferences,
  /** update preferences */
  (newPreferences: VeneerTablePreferences) => void,
  /** reset preferences */
  () => void,
];

export function useTablePreferences<T, U = T>(
  tableName: string,
  columns: BackendColumn<T, U>[], // min length 1
  defaultSortKey: Key<T> = columns[0]?.property,
  defaultSortDirection: "ASC" | "DESC",
  showFiltersByDefault: boolean = true,
): TablePreferencesResult {
  const preferenceName = `${tableName}TablePreferences`;
  const dispatch = useDispatch<Dispatch<RootActionTypes>>();
  const preferencesStr = useUserPreference(preferenceName);
  let preferences = tryParseJson<VeneerTablePreferences>(preferencesStr);

  if (!preferences || typeof preferences !== "object" || Array.isArray(preferences)) {
    preferences = {} as VeneerTablePreferences;
  }

  const defaultColumnsOrder = columns.map((it) => it.property) as string[];
  const defaultVisibleColumns = columns.filter((it) => it.visibleByDefault !== false && it.enabled !== false)
    .map((it) => it.property) as string[];

  const defaultWidths = columns.reduce((obj, { property, width }) => {
    obj[property] = width || "120px";
    return obj;
  }, {} as Record<string, string>);

  // defaultColumnsOrder has entire list of allowed columns.  Filter existing preferences in case a column was removed.
  preferences.columnsOrder = preferences.columnsOrder?.filter((col) => defaultColumnsOrder.includes(col)) ?? defaultColumnsOrder;
  preferences.visibleColumns = preferences.visibleColumns?.filter((col) => defaultColumnsOrder.includes(col)) ?? defaultVisibleColumns;
  preferences.showFilters = preferences.showFilters ?? true;

  preferences.sortBy = {
    column: preferences.sortBy?.column ?? defaultSortKey,
    type: preferences.sortBy?.type ?? defaultSortDirection,
  };

  preferences.widths = {
    ...defaultWidths,
    ...preferences.widths,
  };

  for (const col of defaultColumnsOrder) {
    if (!preferences.columnsOrder.includes(col)) {
      preferences.columnsOrder.push(col);
    }
  }

  preferences.showFilters = preferences.showFilters ?? showFiltersByDefault;

  const cachedPreferences = useCachedObjectMemo(preferences);

  const cachedDefaults: VeneerTablePreferences = useCachedObjectMemo({
    columnsOrder: defaultColumnsOrder,
    visibleColumns: defaultVisibleColumns,
    showFilters: showFiltersByDefault,
    sortBy: { column: defaultSortKey, type: defaultSortDirection },
    widths: defaultWidths,
  });

  const changePreferences = useCallback(
    (newPreferences: VeneerTablePreferences) => {
      dispatch(asyncUpdatePreference(preferenceName, JSON.stringify(newPreferences)) as any);
    },
    [dispatch, preferenceName],
  );

  const resetPreferences = useCallback(
    () => {
      dispatch(asyncUpdatePreference(preferenceName, JSON.stringify(cachedDefaults)) as any);
    },
    [dispatch, preferenceName, cachedDefaults],
  );

  return [cachedPreferences, changePreferences, resetPreferences];
}
